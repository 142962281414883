<template>
  <!--begin::Register-->
  <div class="login-form">
    <v-form class="form" ref="formRegister" v-model="Valid" lazy-validation>
      <!--begin::RegisterUserData-->
      <div class="login-title my-0">
        1. {{ $t("MY_PROFILE.SUBTITLE") }}
        <hr class="login-title-sub" />
      </div>

      <div class="row">
        <div class="form-group col-lg-12">
          <label>
            {{ $t("COMPANIES.COMPANY_CLASS") }}
          </label>
          <select
            class="form-control select-down"
            v-model="Fields.CompanyClassID"
            :rules="[rules.required]"
            required
            @change="onChangeCompanyClass"
          >
            <option
              v-for="cc in CompanyClassOptions"
              :key="cc.CompanyClassID"
              :value="cc.CompanyClassID"
              class="text-primary"
            >
              {{ cc.Name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-12">
          <label>
            {{ $t("MY_PROFILE.FULLNAME") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.Name"
            :placeholder="$t('MY_PROFILE.FULLNAME')"
            :rules="[rules.required]"
            required
          />
        </div>

        <div class="form-group col-lg-12">
          <label>
            {{ $t("MY_PROFILE.PHONE") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.Phone"
            :placeholder="$t('MY_PROFILE.PHONE')"
            :rules="[rules.required]"
            required
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/phone-gray.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="form-group col-lg-12">
          <label>
            {{ $t("MY_PROFILE.EMAIL") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.Email"
            :placeholder="$t('MY_PROFILE.EMAIL')"
            :rules="[rules.required, rules.email]"
            required
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/contact-gray.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="col-lg-12 d-flex align-items-center">
          <inline-svg src="/media/icons/help-alt-5-primary.svg" />
          <span class="pl-3">
            {{ $t("USERS.NEW_EMAIL_INFO") }}
          </span>
        </div>
      </div>
      <!--end::RegisterUserData-->

      <!--begin::RegisterCompanyData-->
      <div class="login-title mt-6 mb-0">
        2. {{ $t("COMPANIES.SUBTITLE_FORM_BASIC") }}
        <hr class="login-title-sub" />
      </div>

      <div class="row">
        <div class="form-group col-lg-12">
          <label>
            {{ $t("COMPANIES.FISCAL_ID") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.FiscalID"
            :placeholder="$t('COMPANIES.FISCAL_ID')"
            :rules="[rules.required]"
            required
          />
        </div>

        <div class="form-group col-lg-12">
          <label>
            {{ $t("COMPANIES.FISCAL_NAME") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.FiscalName"
            :placeholder="$t('COMPANIES.FISCAL_NAME_INFO')"
            :rules="[rules.required]"
            required
          />
        </div>

        <div v-if="Fields.CompanyClassID > 2" class="form-group col-lg-12">
          <label>
            {{ $t("COMPANIES.TRADE_NAME") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.TradeName"
            :placeholder="$t('COMPANIES.TRADE_NAME')"
            :rules="[rules.required]"
            required
          />
        </div>

        <div
          v-if="CompanyTypeID == companyTypesList.ID_SENDER"
          class="form-group col-lg-12"
        >
          <label>
            {{ $t("COMPANIES.BRAND_NAME") }}
            <span class="font-weight-normal">
              ({{ $t("GENERIC.OPTIONAL") }})
            </span>
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.BrandName"
            :placeholder="$t('COMPANIES.BRAND_NAME')"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-12">
          <label>
            {{ $t("COMPANIES.FULL_ADDRESS") }}
          </label>

          <VueGoogleAutocomplete
            ref="autocompleteRegisterAddress"
            id="autocompleteRegisterAddress"
            country="es"
            :fields="['address_components', 'geometry', 'id', 'name']"
            v-model="Fields.Street"
            :rules="[rules.required]"
            required
            classname="form-control"
            placeholder=""
            @placechanged="onAutocompleteRegisterPlaceChanged"
            @inputChange="onAutocompleteRegisterInputChange"
          />
          <div
            v-if="Fields.Street.trim().length < 1"
            class="v-text-field__details pt-2 pl-3 pb-0 m-0"
          >
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  {{ $t("VALIDATION.REQUIRED_FIELD") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-lg-12">
          <label>
            {{ $t("COMPANIES.POSTAL_CODE") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.PostalCode"
            :placeholder="$t('COMPANIES.POSTAL_CODE')"
            required
            :rules="[rules.required]"
            @keyup="onRegisterPostalCodeChanged"
          />
        </div>

        <div class="form-group col-lg-12">
          <label>
            {{ $t("COMPANIES.CITY") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.City"
            :placeholder="$t('COMPANIES.CITY')"
            required
            :rules="[rules.required]"
          />
        </div>

        <div class="form-group col-lg-12">
          <label>
            {{ $t("COMPANIES.PROVINCE") }}
          </label>
          <select
            class="form-control select-down"
            v-model="Fields.ProvinceID"
            required
            :rules="[rules.required]"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>
      </div>
      <!--end::RegisterCompanyData-->

      <!--begin::RegisterContactData-->
      <div class="login-title mt-6 mb-0">
        3. {{ $t("REGISTER.CONTACT_DATA") }}
        <hr class="login-title-sub" />
      </div>

      <div class="d-flex align-items-center mb-3">
        <inline-svg src="/media/icons/help-alt-5-primary.svg" />
        <span class="pl-3">
          {{ $t("REGISTER.CONTACT_DATA_INFO." + this.CompanyTypeID) }}
        </span>
      </div>

      <div class="row">
        <div class="form-group col-lg-12">
          <label>
            {{ $t("COMPANIES.CONTACT_NAME") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.ContactName"
            :placeholder="$t('COMPANIES.CONTACT_NAME')"
            :rules="[rules.required]"
            required
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-12">
          <label>
            {{ $t("COMPANIES.CONTACT_EMAIL") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.ContactEmail"
            :placeholder="$t('COMPANIES.CONTACT_EMAIL')"
            :rules="[rules.required, rules.email]"
            required
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/contact-gray.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="form-group col-lg-12">
          <label>
            {{ $t("COMPANIES.CONTACT_PHONE") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.ContactPhone"
            :placeholder="$t('COMPANIES.CONTACT_PHONE')"
            :rules="[rules.required]"
            required
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/phone-gray.svg" />
            </template>
          </v-text-field>
        </div>
      </div>
      <!--end::RegisterContactData-->

      <!-- begin::SubscriptionOptions -->
      <div class="login-title mt-6 mb-0">
        4. {{ $t("COMPANIES.SUBSCRIPTION_TYPE") }}
        <hr class="login-title-sub" />
      </div>

      <div
        v-for="st in SubscriptionOptions"
        :key="'SType_' + st.SubscriptionTypeID"
        class="primary-box mb-3"
      >
        <div class="primary-box-header bg-transparent">
          <div class="row">
            <div class="col-lg-6">
              <v-radio-group
                v-model="Fields.SubscriptionTypeID"
                row
                style="margin-bottom: -2.5em"
              >
                <v-radio :value="st.SubscriptionTypeID" class="ml-6">
                  <template v-slot:label>
                    <h4 class="mb-0 pt-2 ml-3 text-primary">
                      <strong>
                        {{ st.Name }}
                      </strong>
                    </h4>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <div class="col-lg-6 text-right text-primary">
              <h4
                class="mb-0 pt-4 pr-6"
                v-html="formatSubscriptionPrice(st.PricePerMonth, st.Months)"
              ></h4>
            </div>
          </div>
        </div>
        <div class="primary-box-body">
          <ul>
            <li
              v-for="(descLine, d) in st.Description"
              :key="'Descri_' + st.SubscriptionTypeID + '_' + d"
            >
              {{ descLine }}
            </li>
          </ul>
        </div>
      </div>
      <!-- end::SubscriptionOptions -->

      <!-- begin::Legal -->
      <div class="pl-1 mb-2">
        <v-checkbox v-model="Fields.Accept" required>
          <template v-slot:label>
            <div class="pt-1 ml-3 text-dark">
              {{ $t("GENERIC.ACCEPT_CONDITIONS_1") }}
              <a
                href="/legal/terms"
                target="_blank"
                @click.stop
                class="font-weight-bolder"
              >
                {{ $t("GENERIC.ACCEPT_CONDITIONS_2") }}
              </a>
            </div>
          </template>
        </v-checkbox>
      </div>
      <!-- end::Legal -->

      <!-- begin::Action buttons -->
      <p v-if="requirePayment" class="text-dark mx-3">
        {{ $t("PRICE_SEARCH.STEP3.FINISH.PAYMENT_INTERNAL_INFO_1") }}
        <br />
        {{ $t("PRICE_SEARCH.STEP3.FINISH.PAYMENT_INTERNAL_INFO_2") }}
        <br />
        {{ $t("PRICE_SEARCH.STEP3.FINISH.PAYMENT_INTERNAL_INFO_3") }}
        <br />
        <v-img
          src="/media/logos/multisafepay-logo.png"
          class="my-3"
          style="width: 50%"
        />
      </p>

      <div class="py-6 text-center">
        <button
          ref="formRegisterSubmit"
          class="btn btn-pill btn-tertiary px-6"
          :disabled="!Valid || !Fields.Accept"
          @click="onSubmitRegister"
        >
          {{
            requirePayment
              ? $t("REGISTER.CONTINUE_PAY")
              : $t("REGISTER.CONTINUE_FREE")
          }}
        </button>
      </div>

      <div class="text-center">
        <p class="black">
          {{ $t("REGISTER.ALREADY_REGISTERED_1") }}
          <strong @click="goToLogin">
            {{ $t("REGISTER.ALREADY_REGISTERED_2") }}
          </strong>
        </p>
      </div>
      <!-- end::Action buttons -->
    </v-form>
  </div>
  <!--end::Register-->
</template>

<!-- Load Manager custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/manager/Base.scss";
</style>

<script>
import { mapGetters } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";

import CompanyClassService from "@/core/services/api/v2/companyclass.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import RegisterService from "@/core/services/api/v2/register.service";
import SubscriptionTypeService from "@/core/services/api/v2/subscriptiontype.service";

export default {
  name: "Register",
  components: {
    VueGoogleAutocomplete
  },

  data() {
    return {
      Fields: {
        SubscriptionTypeID: 0,
        CompanyClassID: 1,
        Nick: "",
        Name: "",
        Email: "",
        Phone: "",
        FiscalName: "",
        FiscalID: "",
        TradeName: "",
        BrandName: "",
        ContactName: "",
        ContactPhone: "",
        ContactEmail: "",
        Street: "",
        PostalCode: "",
        City: "",
        ProvinceID: 0,
        Accept: false
      },
      Valid: true,
      CompanyTypeID: 2,
      CompanyClassOptions: [],
      ProvinceOptions: [],
      SubscriptionOptions: [],
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL")
      }
    };
  },

  computed: {
    ...mapGetters(["companyTypesList"]),
    requirePayment() {
      let selectedSTID = this.Fields.SubscriptionTypeID;
      let selectedPrice = 0;
      this.SubscriptionOptions.forEach(s => {
        if (s.SubscriptionTypeID === selectedSTID) {
          selectedPrice = s.PricePerMonth;
        }
      });

      return selectedPrice > 0;
    }
  },

  mounted() {
    // Pick Subscription options per CompanyType
    this.CompanyTypeID = parseInt(this.$route.params.type);
    this.loadSubscriptionOptions();

    // Set parent layout content
    let bgImage = process.env.BASE_URL + "media/bg/imagen-";
    if (this.CompanyTypeID === 3) {
      bgImage += "transp.jpg";
    } else {
      bgImage += "remi.jpg";
    }

    this.$emit("layout-login-aside-background", bgImage);

    let titleByType = this.$i18n.t("REGISTER.BUTTON." + this.CompanyTypeID);
    this.$emit("layout-login-aside-title", titleByType);
    this.$emit("layout-login-aside-description", "");

    // Force "required field" messages
    this.$nextTick(() => {
      document.title = this.$t("HEADER.TITLE") + " " + titleByType;
      this.$refs.formRegister.validate();
    });
  },

  methods: {
    loadSubscriptionOptions() {
      SubscriptionTypeService.listAll(this.CompanyTypeID).then(stList => {
        if (stList.length < 1) {
          // Nothing to do here
          this.goToLogin();
        } else {
          this.SubscriptionOptions = stList;
          this.Fields.SubscriptionTypeID = stList[0].SubscriptionTypeID;
          this.loadCompanyClassOptions();
        }
      });
    },

    loadCompanyClassOptions() {
      this.CompanyClassOptions = [];
      CompanyClassService.listAll().then(response => {
        // As of 2022-01 avoid Class=Particular for Transporters
        if (this.CompanyTypeID != this.companyTypesList.ID_TRANSPORTER) {
          this.CompanyClassOptions = response;
        } else {
          response.forEach(r => {
            if (r.CompanyClassID > 1) {
              this.CompanyClassOptions.push(r);
            }
          });
        }

        this.loadProvinceOptions();
      });
    },

    onChangeCompanyClass() {
      // Force "required field" messages
      this.$nextTick(() => {
        this.$refs.formRegister.validate();
      });
    },

    loadProvinceOptions() {
      this.ProvinceOptions = [];
      ProvinceService.listAll().then(response => {
        this.ProvinceOptions = response;
      });
    },

    onAutocompleteRegisterPlaceChanged(addressData) {
      let newStreet =
        addressData.route +
        (addressData.street_number ? " " + addressData.street_number : "");
      this.Fields.Street = newStreet;

      this.Fields.PostalCode = addressData.postal_code;
      this.Fields.City = addressData.locality;
      this.onRegisterPostalCodeChanged();

      // Clean extra values from Street box
      this.$refs[
        "autocompleteRegisterAddress"
      ].$refs.autocomplete.value = newStreet;
    },

    onAutocompleteRegisterInputChange(e) {
      if (e.newVal.trim().length === 0) {
        this.Fields.Street = "";
      }
    },

    onRegisterPostalCodeChanged() {
      // Convert PostalCode to an item in our ProvinceID selector
      // that's why we restrict VueGoogleAutocomplete component to country="es"
      let newPostalCode = parseInt(this.Fields.PostalCode);
      let newProvinceID = 1;

      for (let p = 0; p < this.ProvinceOptions.length; p++) {
        let maybeProvince = this.ProvinceOptions[p];
        let pcStart = parseInt(maybeProvince.PostalCodeStart);
        let pcEnd = parseInt(maybeProvince.PostalCodeEnd);
        if (newPostalCode >= pcStart && newPostalCode <= pcEnd) {
          newProvinceID = maybeProvince.ProvinceID;
          break;
        }
      }

      this.Fields.ProvinceID = newProvinceID;
    },

    formatSubscriptionPrice(pricePerMonth, months) {
      let price = parseFloat(pricePerMonth);

      if (price === 0) {
        return "<strong>" + this.$t("GENERIC.PRICE_FREE") + "</strong>";
      } else {
        price = price * months;
        return (
          "<strong>" +
          price.toFixed(2).replace(".", ",") +
          " €</strong> / " +
          this.$tc("SUBSCRIPTION_TYPES.PER_MONTHS", months, { count: months })
        );
      }
    },

    onSubmitRegister(e) {
      e.preventDefault();

      if (this.Fields.Accept && this.$refs.formRegister.validate()) {
        // Disable submit button
        this.Valid = false;

        // User profile pages require his Nick,
        // but as of 2021-11 this form doesn't ask for it - fill with Name
        if (this.Fields.Nick.length < 1) {
          this.Fields.Nick = this.Fields.Name;
        }

        // TradeName is also required on Company profile pages,
        // but this form allows it to be empty on non-Enterprises
        if (this.Fields.TradeName.length < 1) {
          this.Fields.TradeName = this.Fields.FiscalName;
        }

        // Send request
        RegisterService.register(this.Fields).then(rawResponse => {
          if (Number.isInteger(rawResponse)) {
            // Something went wrong
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");

            if (rawResponse === 409) {
              whyError = this.$i18n.t("REGISTER.ERROR_409");
            } else if (rawResponse === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });

            // Reset submit button
            this.Valid = true;
          } else {
            // Correcly registered: must pay?
            let paymentUrl = rawResponse.PaymentUrl;
            if (paymentUrl.length > 0) {
              window.location.href = paymentUrl;
            } else {
              this.$router.push("/registerOK");
            }
          }
        });
      }
    },

    goToLogin() {
      this.Fields.SubscriptionTypeID = 0;
      this.$router.push("/login");
    }
  }
};
</script>
